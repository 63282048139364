import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  link: new HttpLink({
    uri: API_URI,
    credentials: 'omit',
    fetch
  }),
  cache: new InMemoryCache({})
});

export default client;
