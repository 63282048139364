module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Urbanist","Open Sans"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cantinorant Business","short_name":"Cantinorant","start_url":"/","background_color":"#fbfbfb","theme_color":"#fbfbfb","display":"minimal-ui","icon":"/var/www/vhosts/cantinorant.com/www/cantinorant/var/assets/logo/cantinorant_c.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"19dc3116c2533ac64058515aa5b1d1d5"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
